import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Box } from 'theme-ui'
import { SEO } from 'components'

function Bunkhouses() {
  const { post } = useStaticQuery(graphql`
    query Bunkhouses {
      post: markdownRemark(frontmatter: { slug: { eq: "bunkhouses" } }) {
        id
        htmlAst
        html
      }
    }
  `)
  return (
    <>
      <SEO title="Bunkhouses" />
      <Box
        sx={{
          boxSizing: 'content-box',
          py: ['l', null, 'xl'],
          px: 'm',
          maxWidth: 'measure',
          mx: 'auto',
          '& ul': {
            pl: ['m', null, 0],
          },
        }}
      >
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
      </Box>
    </>
  )
}

export default Bunkhouses
